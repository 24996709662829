'use client';

import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useGetUserQuery } from '@/RTK/services/userApi';
import { ChevronDown, Newspaper, Search, SquarePen } from 'lucide-react';
import { useSelector } from 'react-redux';

import ProfileImage from '@/components/ui/profile-image';

import { logo, star } from '../../../../../public/images';
import Dropdown from './Dropdown';
import NavItem from './NavItem';
import NotificationIcon from './NotificationIcon';

const Navbar = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const dropdownRef = useRef(null);
  const router = useRouter();
  const token = useSelector((state) => state.auth.token);
  const { data: user } = useGetUserQuery(undefined, {
    skip: !token,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="sticky top-0 z-[50] bg-white border-b">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo and Articles - Left Section */}
          <div className="flex items-center gap-6">
            <div
              className="flex-shrink-0 cursor-pointer"
              onClick={() => router.push('/')}
            >
              <Image
                src={logo}
                alt="logo"
                width={120}
                height={40}
                className="h-8 w-auto"
              />
            </div>

            {/* Articles Link - Desktop Only */}
            {token && (
              <button
                onClick={() => router.push('/articles')}
                className="hidden md:flex items-center gap-2 text-gray-700 hover:text-purple-600 transition-colors"
              >
                <Newspaper className="w-5 h-5" />
                <span className="text-sm">كتاباتك</span>
              </button>
            )}
          </div>

          {/* Search - Center Section */}
          <div className="hidden  sm:flex justify-center items-center max-w-md mx-auto">
            <button
              dir="rtl"
              onClick={() => router.push('/explore/topics')}
              className="  gap-2 w-full max-w-md px-10 py-2 text-gray-500 bg-gray-50 rounded-full hover:bg-gray-100 transition-colors group
              flex justify-start items-center 
              "
            >
              <Search className="h-5 w-5 text-gray-400 group-hover:text-purple-600 transition-colors" />
              <span dir="rtl" className=" text-sm text-gray-400">
                البحث
              </span>
            </button>
          </div>

          {/* Right Section */}
          <div className="flex items-center gap-2">
            {token ? (
              <>
                {/* Premium Badge */}
                <button
                  onClick={() => router.push('/subscribe')}
                  className="flex items-center gap-2 px-3 py-1.5 rounded-full bg-gradient-to-r from-purple-600 to-purple-700 text-white text-sm font-medium hover:from-purple-700 hover:to-purple-800 transition-colors"
                >
                  <Image
                    src={star}
                    alt="star"
                    className="w-4 h-4"
                    width={16}
                    height={16}
                  />
                  <span className="text-sm">باقة الكاتب</span>
                </button>

                {/* Rest of the existing buttons */}
                <NavItem
                  link="/text-editor"
                  title="اكتب"
                  icon={<SquarePen className="w-4 h-4" />}
                  className="hidden sm:flex"
                />
                <NotificationIcon className="md:flex hidden" />

                {/* User Profile */}
                <div className="relative" ref={dropdownRef}>
                  <div className="flex items-center gap-2">
                    <ProfileImage
                      userName={user?.user?.name}
                      src={user?.user?.profileImage}
                      alt="Profile"
                      size="sm"
                      isPremium={user?.user?.isPremium}
                    />
                    <button
                      onClick={() => setOpenDropdown(!openDropdown)}
                      aria-expanded={openDropdown}
                      aria-haspopup="true"
                      aria-label="User menu"
                      className="p-1.5 hover:bg-gray-50 rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    >
                      <ChevronDown
                        className={`w-4 h-4 text-gray-600 transform transition-transform duration-200 ease-out ${
                          openDropdown ? 'rotate-180' : ''
                        }`}
                      />
                    </button>
                  </div>
                  {openDropdown && (
                    <Dropdown
                      user={user}
                      setShowNotifications={setShowNotifications}
                      setOpenDropdown={setOpenDropdown}
                    />
                  )}
                </div>
              </>
            ) : (
              <div className="flex items-center gap-2">
                <button
                  onClick={() => router.push('/explore/topics')}
                  className="p-2 hover:bg-gray-50 sm:hidden rounded-full transition-colors group"
                >
                  <Search className="w-5 h-5 text-gray-400 group-hover:text-purple-600 transition-colors" />
                </button>
                <button
                  onClick={() => router.push('/subscribe')}
                  className="flex items-center gap-2 px-3 py-1.5 rounded-full bg-gradient-to-r from-purple-600 to-purple-700 text-white text-sm font-medium hover:from-purple-700 hover:to-purple-800 transition-colors"
                >
                  <Image
                    src={star}
                    alt="star"
                    className="w-4 h-4"
                    width={16}
                    height={16}
                  />
                  <span className="">باقة الكاتب</span>
                </button>

                <button
                  onClick={() => router.push('/signin')}
                  className="flex items-center gap-2 px-3 py-1.5 rounded-full bg-white text-purple-600 text-sm font-medium  border-2 border-purple-600 transition-colors"
                >
                  {' '}
                  الدخول{' '}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
